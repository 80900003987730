.frame {
  background-color: #57d0b2;
  border: 4px solid;
  border-color: #26e2b3;
  border-radius: 20px;
  height: 400px;
  position: relative;
  width: 508px;
}

.frame .list-wallet {
  height: 360px;
  left: 18px;
  position: absolute;
  top: 68px;
  width: 464px;
}

.frame .list-title-icon {
  height: 72px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 288px;
  width: 464px;
}

.frame .img {
  height: 72px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 96px;
  width: 464px;
}

.frame .list-title-icon-2 {
  height: 72px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 464px;
}

.frame .list-title-icon-3 {
  height: 72px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 192px;
  width: 464px;
}

.frame .nav {
  height: 48px;
  left: 19px;
  position: absolute;
  top: -2px;
  width: 464px;
}

.frame .connect-your-wallet {
  color: #d5fff4;
  font-family: "Urbanist", Helvetica;
  font-size: 24px;
  font-weight: 900;
  left: 91px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  text-align: center;
  top: 11px;
  white-space: nowrap;
}

.frame .icon-close-small {
  height: 24px !important;
  left: 421px !important;
  position: absolute !important;
  top: 18px !important;
  width: 24px !important;
}

.frame .icon-close-small:hover {
  cursor: pointer;
}

.option {
  /*background linear gradient top #009D77 bottom #00372A shifted 25% up*/
  background: linear-gradient(180deg, #02bf90 0%, #008465 100%);
  border-radius: 20px;
}

.option:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
}

/*media query for mobile devices*/

@media (max-width: 768px) {
  .frame {
    overflow: hidden;
    width: 90%;
    height: 90%;

  }


.frame .nav {
  height: 48px;
  width: 100%;
}


  .frame .connect-your-wallet {
    color: #d5fff4;
    font-family: "Urbanist", Helvetica;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 36px;
    position: absolute;
    text-align: center;
    top: 11px;
    left: 10px;
    white-space: nowrap;
  }
}