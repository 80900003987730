
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #303030; /* Color oscuro tipo gris material */
  background-image: url('WHATA-background.jpg');
  background-size: cover; /* Ajusta la imagen para que cubra todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que se repita la imagen */
}

/* Estilos para la barra superior */
.top-bar {
  display: flex;
  align-items: center;
  background-color: #222222;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para el top bar */
}

/* Estilos para el logotipo */
.logo {
  width: 120px; /* Ancho del logotipo */
  height: auto;
}

/* Estilos para el cuerpo de la página */
.body {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
}

/* Estilos para la tarjeta */
.card {
  /*center of the screen*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border-radius: 8px; /* Bordes redondeados */
  aspect-ratio: 1; /* Aspect ratio de la tarjeta */
  display: flex;
  background-color: #0F222222;
  border: 2px solid #26E2B3;
  padding: 10px;
  orientation: vertical;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Estilos para el contenido de la tarjeta */
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Estilos para el botón de conexión */
.connect-button {
  cursor: pointer;
  display: flex;
  width: 464px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  color: white;
  border-radius: 20px;
  border-radius: 40px;
  border: 2px solid #FFF;
}

/* Estilos para el botón de conexión */
.connected-button {
  cursor: pointer;
  display: flex;
  width: 464px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  border-radius: 40px;
  border: 2px solid #20E0B1;
  background: rgba(38, 226, 179, 0.22);
}

.connected-button:hover {
  background: #FF4E4E;
}

.connected-button:hover .connect-text {
  content: "";
  display: none;
}

.connected-button:hover .disconnect-text {
  display: block;
  margin: 0;
}

.connect-text {
  color: white;
  font-size: 12px;
  margin: 0;
}

.disconnect-text {
  display: none;
}

/* Estilos para el botón de conexión */
.sign-button {
  cursor: pointer;
  display: flex;
  width: 464px;
  padding: 16px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  color: white;
  border-radius: 40px;
  border: 2px solid #FFF;
}

.sign-complete {
  cursor: pointer;
  display: flex;
  width: 464px;
  padding: 16px;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  border-radius: 40px;
  border: 2px solid #20E0B1;
  background: rgba(38, 226, 179, 0.22);
}

.error-message {
  color: white;
  padding: 0 20px;
}

.whatalab-logo {
  max-width: 200px;
}

.spinner {
  animation: spin 1s linear infinite;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: #222222;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  color: white
}


.copy-button,
.connect-hashpack-button {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #009688;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  margin-top: 16px;
  color: #009688;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.copy-button,
.connect-hashpack-button,
.close-button {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #009688;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  background-color: rgba(0, 255, 255, 0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobile-text {
  margin-top: 24px;
}

.mobile-button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.footer-logo {
  width: 30%;
}


.header-logo {
  width: 30%;
}


.frame {
  /* center of the screen floating on top of everything*/
  position: fixed;
  z-index: 1000;
}

/*media query for mobile devices*/

@media (max-width: 768px) {
  .body {
    overflow: hidden;
  }
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
  }

  .card-content {
    padding: 20px;
  }

  .connect-button {
    width: 100%;
  }

  .sign-button {
    width: 100%;
  }

  .sign-complete {
    width: 100%;
  }

  .footer-logo {
    width: 50%;
  }

  .header-logo {
    width: 50%;
  }
}